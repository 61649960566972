<template>
    <div class="recommend h100" style="background: #f9f9f9; padding-bottom: 70px;">
        <div class="h100" style="overflow: auto">
            <div class="container h100" style="overflow: initial !important">
                <div class="main_title home_main_title">Recommended <a-button @click="back">Back</a-button></div>
                <div class="head">
                    <a-row :gutter="10">
                        <a-col :span="8">
                            <div class="head_box">
                                <span class="name">Current bill</span>
                                <span class="value">${{$route.query.bill || 0}}</span>
                                <img class="icon" src="/img/icons/p1.png">
                            </div>
                        </a-col>
                        <a-col :span="8">
                            <div class="head_box">
                                <span class="name">New bill</span>
                                <span class="value">${{getNewBill}}</span>
                                <img class="icon" src="/img/icons/p2.png">
                            </div>
                        </a-col>
                        <a-col :span="8">
                            <div class="head_box">
                                <span class="name">Save bill / years</span>
                                <span class="value">${{getSaveBill}}</span>
                                <img class="icon" src="/img/icons/p3.png">
                            </div>
                        </a-col>
                    </a-row>
                </div>
                <a-row :gutter="10">
                    <a-col :span="12">
                        <div class="recommend_image">
                            <img class="recommend_icon" :src="recommended.image">
                        </div>
                    </a-col>
                    <a-col :span="12">
                        <div class="recommend_info">
                            <div class="recommend_name">{{recommended.recommended_name}}</div>
                            <div class="recommended_feature">{{recommended.recommended_feature}}</div>
                            <div class="price">${{recommended.recommended_price}}</div>
                            <div class="recommended_description" v-html="recommended.recommended_description"></div>
                        </div>
                    </a-col>
                </a-row>
                <div class="composition">
                    <div class="composition_title">Composition</div>
                    <div class="product_list">
                        <a-row :gutter="10">
                        <a-col :span="6" v-for="(product, idx) in recommended.products" :key="idx">
                            <div class="product_box" @click="goProduct(product.product_id)">
                                <img class="product_image" :src="product.image">
                                <span class="product_name">{{product.product_name}}</span>
                                <span class="product_description textarea_ellipsis" v-html="product.product_description"></span>
                                <span class="price product_price">${{product.product_price}}</span>
                                <span class="price product_nums">x {{product.nums}}</span>
                            </div>
                        </a-col>
                        </a-row>
                    </div>
                </div>
            </div>
            <div class="bottom_bar">
                <div class="container h100">
                    <div class="flex h100">
                        <span style="color: #fff">Total price: <span class="price">${{recommended.recommended_price}}</span></span>
                        <a-button class="primary btn" @click="goOrder" type="primary">Order</a-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import commonMixins from '@/mixins/commonMixins'
import { getRecommendedInfo } from '@/apis/home/recommended'
export default {
    name: 'RecommendedDetail',
    mixins: [commonMixins],
    data(){
        return {
            recommended: {},
        }
    },
    methods: {
        /**
         * 跳转产品
         */
        goProduct(product_id){
            const { href } = this.$router.resolve({
                path: `/products/${product_id}`,
            });
            window.open(href);
        },
        /**
         * 跳转订单
         */
        goOrder(){
            this.$router.push({path: '/order', query: this.$route.query})
        },
        async getRecommendedInfoHandler(){
            const res = await getRecommendedInfo({recommended_id: this.$route.query.recommended_id})
            this.recommended = res.data
        }
    },
    computed:{
        getSaveBill(){
            const curr_bill = this.$route.query.bill || 0
            const new_bill = this.getNewBill
            const bill = (Number(curr_bill) - Number(new_bill)) * 4
            return bill
        },
        getNewBill(){
            const curr_bill = this.$route.query.bill || 0
            const save_ratio = this.recommended.save_ratio
            return curr_bill * Number(save_ratio)
        }
    },
    created(){
        this.getRecommendedInfoHandler()
    }
}
</script>
<style lang="stylus" scoped>       
.head_box
    padding 10px 20px
    box-shadow 0 10px 10px 0 rgba(0,0,0,.05)     
    background #fff
    border-radius 10px  
    display flex
    flex-direction column
    position relative
    margin-bottom 10px
    .name
        color #abb1bf
    .value
        font-size 26px
    .icon
        width 40px
        position absolute
        right 10px
        top 18px
.recommend_image
    padding 10px
    background #fff
    border-radius 10px
    text-align center
    box-shadow 0 10px 10px 0 rgba(0,0,0,.05)  
    .recommend_icon
        width 60%
        height 330px
        object-fit cover
.recommend_info
    padding 20px
    height 350px
    background #fff
    border-radius 10px
    box-shadow 0 10px 10px 0 rgba(0,0,0,.05)  
    .recommend_name
        font-size 26px
        margin-bottom 10px
    .recommended_feature
        font-size 13px
    .recommended_description
        padding-top 10px
        margin-top 10px
        border-top 1px solid #eee
        font-size 12px
        color #abb1bf
        overflow hidden
.price
    font-size 26px
    color #43af52
.composition
    margin-top 20px
    .composition_title
        font-size 20px
        margin-bottom 20px
    .product_box
        padding 20px
        border-radius 10px
        background #fff
        box-shadow 0 10px 10px 0 rgba(0,0,0,.05)  
        margin-bottom 10px
        border 1px solid #fff
        cursor pointer
        transition .5s
        display flex
        flex-direction column
        &:hover
            border 1px solid #43af52
        .product_image
            width 40%
            height 100px
            object-fit cover
            margin-bottom 10px
            margin auto
        .product_name
            font-size 16px
            margin-top 10px
            overflow hidden
            text-overflow ellipsis
            display block
            white-space nowrap
        .product_description
            font-size 13px
            color #abb1bf
        .product_price
            font-size 14px !important
        .product_nums
            font-size 13px
            color #000
.bottom_bar .flex
    justify-content space-between
    align-items center
</style>